"use client";
import type { ProductListingEntry } from "api/products/types";
import { useLocalStorage, useIsClient } from "usehooks-ts";
import { cn } from "../../utils";
import { Icon } from "../atoms/icon/icon";
import { Button } from "../atoms/button";

export type CompareProduct = Pick<
  ProductListingEntry,
  "kieskeurigId" | "title" | "thumbnail"
>;

const CompareBannerCompare = () => {
  const isClient = useIsClient();
  const [compareProducts, setCompareProducts, removeCompareProducts] =
    useLocalStorage<CompareProduct[]>("product_compare", []);

  const handleRemove = (id: number) => {
    const newProducts = [...compareProducts].filter(
      (product) => product.kieskeurigId !== id
    );

    setCompareProducts(newProducts);

    window.dispatchEvent(
      new CustomEvent("product-compare-change", { detail: newProducts })
    );
  };

  const handleRemoveAll = () => {
    removeCompareProducts();

    window.dispatchEvent(
      new CustomEvent("product-compare-change", { detail: [] })
    );
  };

  const getCompareUrl = () => {
    const params = new URLSearchParams();

    compareProducts.forEach((product) =>
      params.append("product", product.kieskeurigId.toString())
    );

    return `/productvergelijk?${params.toString()}`;
  };

  if (!isClient || compareProducts.length === 0) return;

  return (
    <div className="fixed left-1/2 -translate-x-1/2 bottom-0 z-20 container flex justify-center">
      <div className="relative inline-block w-full lg:w-5/6 shadow-lg">
        <input
          className={cn(
            "absolute left-0 top-0 w-full h-full peer appearance-none cursor-pointer",
            "checked:fixed checked:h-10"
          )}
          type="checkbox"
        />

        <div className="bg-[#b2dbf0] flex items-center font-semibold gap-2 px-2 h-10 text-sm w-full">
          <Icon icon="chevronBoldDown" size={12} />
          Vergelijk ({compareProducts.length})
          <a
            href={getCompareUrl()}
            className="ml-auto border-l pl-2 text-foreground -z-10"
          >
            Naar vergelijking
          </a>
        </div>

        <div className="hidden peer-checked:flex flex-col bg-card p-4 grid-cols-4 peer-checked:lg:grid">
          {compareProducts.map((product) => (
            <div
              key={product.kieskeurigId}
              className="border-r relative flex gap-4 lg:flex-col"
            >
              <button
                type="button"
                className="text-link absolute right-4 top-0"
                onClick={() => handleRemove(product.kieskeurigId)}
              >
                <Icon icon="trash" size={12} />
              </button>

              <div className="w-[90px] h-[90px] inline-flex items-center justify-center lg:w-full lg:h-[120px]">
                {product.thumbnail !== undefined && (
                  <img
                    src={`${product.thumbnail.split("?")[0]}?h=120&trim=auto`}
                    alt={product.title}
                  />
                )}
              </div>

              <span className="text-link text-sm text-center px-2 block my-6 lg:my-0">
                {product.title}
              </span>
            </div>
          ))}

          <div className="hidden flex-col justify-center gap-2 px-4 lg:flex">
            <button type="button" onClick={handleRemoveAll} className="text-sm">
              Wis producten
            </button>
            <Button asChild>
              <a href={getCompareUrl()}>Naar vergelijking</a>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { CompareBannerCompare };
